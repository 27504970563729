<template>
  <div class="survey-question">
    <div class="question-conainer">
      <div class="question">{{ question.text }}</div>

      <div v-if="starRatingType" class="star-rating">
        <label v-for="(rating, index) in question.options" :key="index" class="star-rating-star"
               :class="{'is-selected': isSelected(rating) }"
               v-on:click="setStarRating(rating)" v-on:mouseover="onStarRatingOver(rating)" v-on:mouseout="onStarRatingOut">
          <input class="star-rating star-rating-checkbox" type="radio" :id="`option-${index}`" :value="rating" v-model="answer">
          ★
        </label>
        <div class="star-rating-range">
          <span>1</span>
          <span>{{ question.options.length }}</span>
        </div>
      </div>

      <input v-else-if="textType" class="text-input" :class="{ empty: isInputEmpty }" placeholder="התשובה שלך"
             v-on:input="setAnswer({ questionName: question.name, answer: $event.target.value })"
             v-on:focus="onTextFocus"
             v-on:blur="onTextBlur"/>
    </div>
    <div class="seperator"/>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'SurveyQuestion',
  props: {
    question: {
      type: Object,
      required: true,
    },
    setAnswer: {
      type: Function,
      required: false,
    },
    questionTypes: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      answer: null,
      tempAnswer: null,
    };
  },
  computed: {
    isInputEmpty() {
      return _.isEmpty(this.answer);
    },
    starRatingType() {
      return this.question.type === this.questionTypes.STAR_RATING;
    },
    textType() {
      return this.question.type === this.questionTypes.TEXT;
    },
  },
  methods: {
    onStarRatingOver(index) {
      this.tempAnswer = index;
      this.setAnswer({ questionName: this.question.name, answer: index });
    },
    onStarRatingOut() {
      this.tempAnswer = null;
      this.setAnswer({ questionName: this.question.name, answer: this.answer });
    },
    setStarRating(value) {
      this.answer = value;
      this.setAnswer({ questionName: this.question.name, answer: value });
    },
    onTextFocus(e) {
      e.target.placeholder = '';
    },
    onTextBlur(e) {
      e.target.placeholder = 'התשובה שלך';
    },
    isSelected(rating) {
      const { tempAnswer } = this;
      const selectedAnswer = this.answer || tempAnswer;
      return selectedAnswer != null && selectedAnswer >= rating;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .survey-question {
    .question-conainer {
      padding: 30px 30px 5px 30px;
      text-align: center;

      .question {
        font-weight: bold;
        font-size: 22px;
        color: $riseup_blue;
        padding-bottom: 16px;
        line-height: 26px;
      }
      input {
        font-size: 16px;
        line-height: 16px;
      }

      .star-rating {
        padding-bottom: 16px;
        text-align: center;

        .star-rating-range {
          display: flex;
          justify-content: space-between;
          padding: 0px 43px;
          font-size: 15px;
          color: $riseup_gray_2;
        }

        &-star {
          display: inline-block;
          padding: 4px;
          vertical-align: middle;
          line-height: 1;
          font-size: 2em;
          color: $riseup_gray_2;
          transition: color .2s ease-out;

          &:hover {
            cursor: pointer;
          }

          &.is-selected {
            color: $riseup_yellow;
          }
        }

        &-checkbox {
          position: absolute;
          overflow: hidden;
          clip: rect(0 0 0 0);
          height: 1px;
          width: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
        }
      }

    }

    .text-input {
      width: 100%;
      margin-bottom: 25px;
      font-size: 16px;
      font-family: SimplerPro;
      text-align: right;
      outline: 0;
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid $riseup_gray_2;
      &:focus {
        border-bottom: 2px solid $riseup_blue;
      }
      &:not(.empty) {
        border-bottom: 2px solid $riseup_blue;
      }
    }

    .seperator {
      height: 1px;
      border: solid 1px $riseup_gray_0;
    }
  }
</style>
