<template>
  <div>
    <div class="title">{{ pageTitle }}</div>
    <div class="sub-title">{{ pageSubTitle }}</div>
    <div class="questions-container">
      <survey-question v-for="(question, index) in questions"
                       :key="'question-' + index"
                       :question="question"
                       :set-answer="setAnswer"
                       :question-types="questionTypes"/>
      <div class="button-container">
        <riseup-button class="send-button"
                       :action="buttonAction"
                       :title="buttonTitle"
                       :disabled="requiredAnswersAreEmpty"/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';

import SurveyQuestion from './SurveyQuestion.vue';

export default {
  name: 'SurveyPage',
  props: {
    questionTypes: {
      type: Object,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    pageSubTitle: {
      type: String,
      required: false,
    },
    setAnswer: {
      type: Function,
      require: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonAction: {
      type: Function,
      required: true,
    },
  },
  components: {
    SurveyQuestion,
    RiseupButton: BaseUI.RiseupButton,
  },
  computed: {
    requiredAnswersAreEmpty() {
      const requiredAnswers = _.filter(this.questions, question => question.required === true);
      return _.some(requiredAnswers, question => (question.answer === null || (_.isString(question.answer) && _.isEmpty(question.answer))));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .title {
    padding-top: 9px;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.56;
    text-align: right;
  }

  .sub-title {
    margin-top: 5px;
    font-size: 20px;
    line-height: 23px;
    text-align: right;
  }

  .questions-container {
    width: 100%;
    margin-top: 13px;
    border-radius: 3px;
    box-shadow: 2px 2px 8px 0 $riseup_gray_1;

    .button-container {
      padding: 30px;

      .send-button {
        width: 100%;
      }
    }
  }
</style>
