<template>
  <div class="csat-container">
    <survey-page v-if="!surveyCompleted"
                 :questions="questions"
                 :question-types="questionTypes"
                 :page-title="`היי ${this.householdName},`"
                 :pageSubTitle="'רצינו לדעת -'"
                 :set-answer="setAnswer"
                 :buttonTitle="'שליחה'"
                 :buttonAction="saveSurveyAnswers"/>
    <alert-page v-else
                :img="require('@/assets/generic-success.png')"
                :title="'תודה!'"
                :showButton="false">
      <template v-slot:content>התשובות יעזרו לנו להמשיך ולשפר את השירות 💪</template>
    </alert-page>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';

import Segment from '../../../../../Segment';
import SurveyPage from '../../../../../base-components/survey/SurveyPage.vue';
import AlertPage from '../../../../../base-components/AlertPage.vue';

const QUESTION_TYPES = {
  TEXT: 'text',
  STAR_RATING: 'star-rating',
};

const QUESTIONS = [
  {
    name: 'CSATScore',
    text: 'איך היה השירות שקיבלת?',
    type: QUESTION_TYPES.STAR_RATING,
    options: [1, 2, 3, 4, 5],
    answer: null,
    required: true,
  },
  {
    name: 'CSATExperience',
    text: 'נשמח לשמוע עוד:',
    type: QUESTION_TYPES.TEXT,
    answer: null,
    required: false,

  },
];

export default {
  name: 'CSATSurvey',
  components: {
    SurveyPage,
    AlertPage,
  },
  data() {
    return {
      questions: QUESTIONS,
      questionTypes: QUESTION_TYPES,
      intercomConversationId: '',
      surveyCompleted: false,
    };
  },
  created() {
    this.intercomConversationId = this.$route.query.conversationId;
  },
  computed: {
    ...mapGetters('session', ['householdName']),
  },
  methods: {
    setAnswer({ questionName, answer }) {
      const questionIndex = this.questions.findIndex(question => question.name === questionName);
      Vue.set(this.questions[questionIndex], 'answer', answer);
    },
    async saveSurveyAnswers() {
      const surveyAnswers = _.chain(this.questions).keyBy(question => question.name)
        .mapValues(question => question.answer).value();
      Segment.trackUserInteraction(
        'CSATSurveyAnsweredClicked',
        { surveyAnswers, intercomConversationId: this.intercomConversationId },
      );
      this.surveyCompleted = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../../../../../scss/mixins';

  .csat-container {
    @include responsive-padding;
    @include responsive-padding-top-bottom;
  }
</style>
